// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Fonts */
`, "",{"version":3,"sources":["webpack://./src/root.css"],"names":[],"mappings":"AAAA,UAAU","sourcesContent":["/* Fonts */\n@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;700&display=swap');\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
